import { render, staticRenderFns } from "./RoomsDetails.vue?vue&type=template&id=130b7c5d"
import script from "./RoomsDetails.vue?vue&type=script&lang=js"
export * from "./RoomsDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports